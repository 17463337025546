const modals = document.querySelectorAll(".modal");
const openModal = document.querySelectorAll(".button--open");
const closeModal = document.querySelectorAll(".button--close");

openModal.forEach((button) => {
  button.addEventListener("click", () => {
    let target = button.getAttribute("data-target");
    let modal = document.querySelector(target);
    modal.showModal();
  });
});

// const welcomeMessage = document.querySelector('#welcomeMessage');

// window.onload = () => welcomeMessage.showModal();

closeModal.forEach((button) => {
  button.addEventListener("click", () => {
    modals.forEach((item) => item.close());
  });
});

document.addEventListener("DOMContentLoaded", () => {
  const urlParams = new URLSearchParams(window.location.search);
  const hasSuccessParam = urlParams.has("success");
  const successParam = urlParams.get("success");
  const successDiv = document.getElementById("success-container");
  const successHeading = document.getElementById("success-message");

  const success = "Email sent successfully!";
  const fail = "Failed to send email.";

  successHeading.textContent = successParam === "1" ? success : fail;
  if (hasSuccessParam) return successDiv.showModal();
});

document.addEventListener("DOMContentLoaded", () => {
  const removeButton = document.getElementById("remove-button");
  removeButton.addEventListener("click", () => {
    window.location.href = removeURLParameter(window.location.href, "success");
  });
});

function removeURLParameter(url, parameter) {
  const urlParts = url.split("?");
  if (urlParts.length >= 2) {
    const params = new URLSearchParams(urlParts[1]);
    params.delete(parameter);
    return urlParts[0] + (params.toString() ? "?" + params.toString() : "");
  }
  return url;
}
